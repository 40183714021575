import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/right-arrow-black.svg'


const _hoisted_1 = { class: "blog_card" }
const _hoisted_2 = { class: "blog_img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "badge" }
const _hoisted_5 = { class: "blog_content" }
const _hoisted_6 = { class: "blog_info" }
const _hoisted_7 = { class: "blog_date cmn_blog_info" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "author cmn_blog_info"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "blog_excrept" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'BlogDetail', params: { slug: _ctx.blog.slug } }
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.blog.featuredimage.url,
            alt: ""
          }, null, 8, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.blog.category), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, {
          class: "blog_title",
          to: { name: 'BlogDetail', params: { slug: _ctx.blog.slug } }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.blog.title), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            src: _ctx.accessTime,
            alt: "",
            class: "img-fluid"
          }, null, 8, _hoisted_8),
          _createElementVNode("p", null, _toDisplayString(_ctx.displayedTime), 1)
        ]),
        (_ctx.blog.author)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("img", {
                src: _ctx.blogAvar,
                alt: "",
                class: "img-fluid"
              }, null, 8, _hoisted_10),
              _createElementVNode("p", null, _toDisplayString(_ctx.blog.author.name), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.blog.excerpt), 1),
      _createVNode(_component_router_link, {
        class: "cm_bg cm-btn",
        to: { name: 'BlogDetail', params: { slug: _ctx.blog.slug } }
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Read Now "),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "ml-1"
          }, null, -1)
        ])),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}